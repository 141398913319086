import React, { useState } from "react";

const QuizForm = () => {
  const [quiz, setQuiz] = useState({
    title: "",
    description: "",
    expiry: "",
    scheduled_date: "",
    organized_by: "",
    questions: [
      {
        question: "",
        image: "",
        options: [{ text: "", image: "" }],
        rightOption: null,
      },
    ],
  });

  const handleQuizChange = (e) => {
    const { name, value } = e.target;
    setQuiz({ ...quiz, [name]: value });
  };

  const handleQuestionChange = (index, field, value) => {
    const updatedQuestions = [...quiz.questions];
    updatedQuestions[index][field] = value;
    setQuiz({ ...quiz, questions: updatedQuestions });
  };

  const handleOptionChange = (qIndex, oIndex, field, value) => {
    const updatedQuestions = [...quiz.questions];
    updatedQuestions[qIndex].options[oIndex][field] = value;
    setQuiz({ ...quiz, questions: updatedQuestions });
  };

  const addQuestion = () => {
    setQuiz({
      ...quiz,
      questions: [
        ...quiz.questions,
        {
          question: "",
          image: "",
          options: [{ text: "", image: "" }],
          rightOption: null,
        },
      ],
    });
  };

  const addOption = (qIndex) => {
    const updatedQuestions = [...quiz.questions];
    updatedQuestions[qIndex].options.push({ text: "", image: "" });
    setQuiz({ ...quiz, questions: updatedQuestions });
  };

  const removeQuestion = (qIndex) => {
    const updatedQuestions = quiz.questions.filter(
      (_, index) => index !== qIndex
    );
    setQuiz({ ...quiz, questions: updatedQuestions });
  };

  const removeOption = (qIndex, oIndex) => {
    const updatedQuestions = [...quiz.questions];
    updatedQuestions[qIndex].options = updatedQuestions[qIndex].options.filter(
      (_, index) => index !== oIndex
    );
    setQuiz({ ...quiz, questions: updatedQuestions });
  };

  const handleSubmit = () => {
    console.log(quiz);
    // Send quiz data to the backend
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-bold mb-4">Create a Quiz</h1>
      <div className="mb-4">
        <label className="block font-medium mb-1">Quiz Title</label>
        <input
          type="text"
          name="title"
          placeholder="Quiz Title"
          value={quiz.title}
          onChange={handleQuizChange}
          className="w-full p-2 border rounded mb-2"
        />
        <label className="block font-medium mb-1">Quiz Description</label>
        <textarea
          name="description"
          placeholder="Quiz Description"
          value={quiz.description}
          onChange={handleQuizChange}
          className="w-full p-2 border rounded mb-2"
        ></textarea>
        <label className="block font-medium mb-1">Expiry Date</label>
        <input
          type="date"
          name="expiry"
          value={quiz.expiry}
          onChange={handleQuizChange}
          className="w-full p-2 border rounded mb-2"
        />
        <label className="block font-medium mb-1">Scheduled Date</label>
        <input
          type="date"
          name="scheduled_date"
          value={quiz.scheduled_date}
          onChange={handleQuizChange}
          className="w-full p-2 border rounded mb-2"
        />
        <label className="block font-medium mb-1">Organized By</label>
        <input
          type="text"
          name="organized_by"
          placeholder="Organized By"
          value={quiz.organized_by}
          onChange={handleQuizChange}
          className="w-full p-2 border rounded mb-2"
        />
      </div>

      {quiz.questions.map((question, qIndex) => (
        <div key={qIndex} className="mb-4 border p-4 rounded bg-white">
          <label className="block font-medium mb-1">Question</label>
          <input
            type="text"
            placeholder="Question"
            value={question.question}
            onChange={(e) =>
              handleQuestionChange(qIndex, "question", e.target.value)
            }
            className="w-full p-2 border rounded mb-2"
          />
          <label className="block font-medium mb-1">
            Question Image URL (optional)
          </label>
          <input
            type="text"
            placeholder="Question Image URL (optional)"
            value={question.image}
            onChange={(e) =>
              handleQuestionChange(qIndex, "image", e.target.value)
            }
            className="w-full p-2 border rounded mb-2"
          />
          {question.options.map((option, oIndex) => (
            <div key={oIndex} className="flex items-center mb-2">
              <div className="w-1/2 pr-2">
                <label className="block font-medium mb-1">Option Text</label>
                <input
                  type="text"
                  placeholder="Option Text"
                  value={option.text}
                  onChange={(e) =>
                    handleOptionChange(qIndex, oIndex, "text", e.target.value)
                  }
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="w-1/2 pl-2">
                <label className="block font-medium mb-1">
                  Option Image URL (optional)
                </label>
                <input
                  type="text"
                  placeholder="Option Image URL (optional)"
                  value={option.image}
                  onChange={(e) =>
                    handleOptionChange(qIndex, oIndex, "image", e.target.value)
                  }
                  className="w-full p-2 border rounded"
                />
              </div>
              <button
                onClick={() => removeOption(qIndex, oIndex)}
                className="text-red-500 ml-2"
              >
                Remove Option
              </button>
            </div>
          ))}
          <button
            onClick={() => addOption(qIndex)}
            className="text-blue-500 text-sm"
          >
            + Add Option
          </button>
          <button
            onClick={() => removeQuestion(qIndex)}
            className="text-red-500 text-sm ml-4"
          >
            Remove Question
          </button>
        </div>
      ))}

      <button
        onClick={addQuestion}
        className="bg-blue-500 text-white py-2 px-4 rounded"
      >
        + Add Question
      </button>
      <button
        onClick={handleSubmit}
        className="bg-green-500 text-white py-2 px-4 rounded ml-4"
      >
        Submit Quiz
      </button>
    </div>
  );
};

export default QuizForm;
