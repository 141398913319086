import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { RxHamburgerMenu } from "react-icons/rx";
import { Popover, PopoverBody } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { getAuth, signOut } from "firebase/auth";
import { useAuth } from "../helper/AuthContext";
import Swal from "sweetalert2";

export default function MenuBarMobile({ setter }) {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const { user, isAuthenticated, logout } = useAuth();

  // console.log(user);

  const logOut = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        logout();
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: "Something went wrong",
          icon: "error",
        });
      });
  };

  return (
    <nav className="z-20 top-0 left-0 right-0 h-[60px] bg-[#00347a] flex [&>*]:my-auto px-2">
      <button
        className="text-4xl flex text-white md:hidden"
        onClick={() => {
          setter((oldVal) => !oldVal);
        }}
      >
        <RxHamburgerMenu />
      </button>
      <div className="w-full text-3xl flex text-white pr-4 justify-end">
        {isAuthenticated && (
          <>
            <img
              id="Popover1"
              width={30}
              height={30}
              src={`${user?.profileImg}`}
              alt={`${user?.name}`}
              className=" rounded-full"
            />
            <Popover
              placement="bottom"
              isOpen={popoverOpen}
              target="Popover1"
              toggle={() => {
                setPopoverOpen(!popoverOpen);
              }}
            >
              <PopoverBody>
                <div className="row w-[150px]">
                  {isAuthenticated && (
                    <>
                      <div className="col-12">
                        <p className="">{user?.name}</p>
                      </div>
                      <div className="col-12">
                        <div
                          className="btn btn-outline-primary"
                          onClick={logOut}
                        >
                          Logout
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </PopoverBody>
            </Popover>
          </>
        )}
        {/* <FaUser /> */}
      </div>
    </nav>
  );
}
