import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const TAG_API = {
    addTag: async (token, payload) => {
        return axios.request({
            method: 'post',
            url: `${BACKEND_URL}add-tag`,
            data: payload,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },
    getAllTags: async (page, token) => {
        return axios.request({
            method: 'get',
            url: `${BACKEND_URL}get-tags/${page}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },
    updateTag: async (token, payload, id) => {
        return axios.request({
            method: 'put',
            url: `${BACKEND_URL}update-tag/${id}`,
            data: payload,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },
    deleteTag: async (token, id) => {
        return axios.request({
            method: 'delete',
            url: `${BACKEND_URL}delete-tag/${id}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }
}