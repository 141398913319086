import React, { Suspense, useState, useEffect } from "react";
import { COMMENT_API } from "../services/CommentService";
import { useParams } from "react-router-dom";
import { useAuth } from "../helper/AuthContext";
const Comment = React.lazy(() => import("../components/Comment"));

const CommentsPage = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const [comments, setComments] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  console.log(user.token);

  const fetchComments = async (currentPage) => {
    try {
      setLoading(true);
      const response = await COMMENT_API.getAllComments(
        user.token,
        id,
        currentPage
      );
      const data = await response.data.comments;
      setComments((prev) => [...prev, ...data]);
      setHasMore(data.length > 0);
    } catch (error) {
      console.error("Error fetching comments:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchComments(page);
  }, [page]);

  const loadMore = () => {
    if (hasMore) setPage((prevPage) => prevPage + 1);
  };

  console.log(comments);

  return (
    <div className="p-4">
      <h2 className="text-2xl font-semibold mb-4">Comments</h2>
      <Suspense fallback={<p>Loading comments...</p>}>
        {comments.map((comment) => (
          <Comment key={comment.id} comment={comment} />
        ))}
      </Suspense>
      {loading && <p className="text-center mt-4">Loading...</p>}
      {hasMore && !loading && (
        <button
          onClick={loadMore}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg mt-4 mx-auto block"
        >
          Load More
        </button>
      )}
    </div>
  );
};

export default CommentsPage;
