import React, { useEffect, useState } from "react";
import { useAuth } from "../helper/AuthContext";
import { TAG_API } from "../services/tagsService";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import {
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap"; // Importing Spinner and Pagination from reactstrap
import Swal from "sweetalert2";

const Tags = () => {
  const [allTags, setAllTags] = useState([]);
  const [tagLabel, setTagLabel] = useState("");
  const [tagValue, setTagValue] = useState("");
  const [hitUrl, setHitUrl] = useState(false);
  const [error, setError] = useState({
    tagLabel: { message: "", error: false },
    tagValue: { message: "", error: false },
  });

  const [page, setPage] = useState(1); // Current page state
  const [totalPages, setTotalPages] = useState(1); // Total pages state
  const [loading, setLoading] = useState(false); // Loading state for data fetching

  const { user } = useAuth();

  useEffect(() => {
    const getTags = async () => {
      setLoading(true);
      try {
        const res = await TAG_API.getAllTags(page, user.token);
        if (res.status === 200) {
          setAllTags(res.data);
          setTotalPages(5); // Set total pages from API
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    getTags();
  }, [hitUrl, page]);

  const handleSave = async () => {
    let err = 0;
    if (tagLabel.length < 1) {
      err += 1;
      setError((prev) => ({
        ...prev,
        tagLabel: {
          ...prev.tagLabel,
          message: "Please Enter a valid Label",
          error: true,
        },
      }));
    }
    if (tagValue.length < 1) {
      err += 1;
      setError((prev) => ({
        ...prev,
        tagValue: {
          ...prev.tagValue,
          message: "Please Enter a valid Value",
          error: true,
        },
      }));
    }

    if (err === 0) {
      const payload = {
        label: tagLabel,
        value: tagValue,
      };
      const res = await TAG_API.addTag(user.token, payload);
      if (res.data.statusCode === 200) {
        setHitUrl(!hitUrl);
        setTagLabel("");
        setTagValue("");
      }
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleDelete = async (id) => {
    try {
      const res = await TAG_API.deleteTag(user.token, id);
      if (res.status === 200) {
        Swal.fire({
          title: "Success",
          icon: "success",
          text: "Successfully deleted",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "error",
        icon: "error",
        text: "Something went wrong",
      });
      console.log(error);
    }
  };

  function handleEdit(ele) {
    setTagLabel(ele.label);
    setTagValue(ele.value);
    document.getElementById("editModal").setAttribute("data-id", ele._id);
  }

  function validateForm() {
    let isValid = true;
    if (tagLabel === "") {
      isValid = false;
    }
    if (tagLabel === "") {
      isValid = false;
    }

    return isValid;
  }

  async function handleEditSave() {
    const editModal = document.getElementById("editModal");
    try {
      const id = editModal.getAttribute("data-id");

      if (validateForm()) {
        const payload = { label: tagLabel, value: tagValue };
        const res = await TAG_API.updateTag(user.token, payload, id);
        if (res.status === 200) {
          Swal.fire({
            title: "Success",
            text: "Successfully edited",
            timer: 1000,
          }).then(() => {
            editModal.classList.remove("show");
            setHitUrl(!hitUrl);
            setTagLabel("");
            setTagValue("");
          });
        }
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Something went wrong",
        timer: 1000,
      }).then(() => editModal.classList.remove("show"));
    }
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row mt-2">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4 className="fw-bold">Add Tags</h4>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-6">
                    <label htmlFor="tagText" className="fw-semibold mb-2">
                      Tag Label
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="tagText"
                      placeholder="Please Enter Tag Label..."
                      onChange={(e) => setTagLabel(e.target.value)}
                      value={tagLabel}
                    />
                    {error.tagLabel.error && (
                      <div className="text-red-500">
                        {error.tagLabel.message}
                      </div>
                    )}
                  </div>
                  <div className="col-6">
                    <label htmlFor="tagValue" className="fw-semibold mb-2">
                      Tag Value
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="tagValue"
                      placeholder="Please Enter Tag Value..."
                      onChange={(e) => setTagValue(e.target.value)}
                      value={tagValue}
                    />
                    {error.tagValue.error && (
                      <div className="text-red-500">
                        {error.tagValue.message}
                      </div>
                    )}
                  </div>
                  <div className="col-sm-auto mt-2">
                    <div className="btn btn-primary" onClick={handleSave}>
                      Save
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Tags List Section */}
          <div className="col-12 mt-2">
            <div className="card">
              <div className="card-header">
                <h4 className="fw-bold">All Tags</h4>
              </div>
              <div className="card-body">
                {loading ? (
                  <div className="text-center">
                    <Spinner style={{ width: "3rem", height: "3rem" }} />
                  </div>
                ) : (
                  <div className="row">
                    {allTags &&
                      allTags.map((ele) => (
                        <div className="col-sm-auto my-2" key={ele._id}>
                          <div className="px-2 py-1 bg-blue-500 text-white rounded-md flex items-center gap-2">
                            <div>{ele.label}</div>
                            <FaEdit
                              className="text-yellow-300 text-sm hover:text-yellow-500 duration-500"
                              data-bs-toggle="modal"
                              data-bs-target="#editModal"
                              onClick={() => handleEdit(ele)}
                            />
                            <MdDelete
                              className="text-red-500 text-sm hover:text-red-400 duration-500"
                              onClick={() => handleDelete(ele._id)}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>

            {/* Pagination Section */}
            <nav aria-label="Page navigation" className="mt-3">
              <Pagination className="justify-content-center">
                <PaginationItem disabled={page === 1}>
                  <PaginationLink
                    previous
                    onClick={() => handlePageChange(page - 1)}
                  />
                </PaginationItem>
                {Array.from({ length: totalPages }, (_, index) => (
                  <PaginationItem key={index} active={page === index + 1}>
                    <PaginationLink onClick={() => handlePageChange(index + 1)}>
                      {index + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={page === totalPages}>
                  <PaginationLink
                    next
                    onClick={() => handlePageChange(page + 1)}
                  />
                </PaginationItem>
              </Pagination>
            </nav>
          </div>

          {/* Bootstrap Modal */}
          <div
            className={`modal fade`}
            // style={{ display: showModal ? "block" : "none" }}
            tabIndex="-1"
            aria-labelledby="editTagModalLabel"
            aria-hidden="true"
            role="dialog"
            id="editModal"
          >
            <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="editTagModal">
                    Edit Tag
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    data-bs-dismiss="modal"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-6">
                      <label
                        htmlFor="modalTagLabel"
                        className="fw-semibold mb-2"
                      >
                        Tag Label
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="modalTagLabel"
                        placeholder="Edit Tag Label..."
                        onChange={(e) => setTagLabel(e.target.value)}
                        value={tagLabel}
                      />
                      {error.tagLabel.error && (
                        <div className="text-danger">
                          {error.tagLabel.message}
                        </div>
                      )}
                    </div>
                    <div className="col-6">
                      <label
                        htmlFor="modalTagValue"
                        className="fw-semibold mb-2"
                      >
                        Tag Value
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="modalTagValue"
                        placeholder="Edit Tag Value..."
                        onChange={(e) => setTagValue(e.target.value)}
                        value={tagValue}
                      />
                      {error.tagValue.error && (
                        <div className="text-danger">
                          {error.tagValue.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleEditSave}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tags;
