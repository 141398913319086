import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../helper/AuthContext";

const ViewNews = () => {
  const backEndUrl = process.env.REACT_APP_BACKEND_URL;
  const { id } = useParams();
  const [news, setNews] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    if (id) {
      getNews();
    }
  }, [id]);

  const getNews = async () => {
    const response = await axios.get(`${backEndUrl}get-particular-news/${id}`, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    const data = response.data;
    setNews(data);
  };

  const getCategory = (ele) => {
    switch (ele) {
      case 1: {
        return "SC/ST";
      }
      case 2: {
        return "OBC";
      }
      case 3: {
        return "GENERAL";
      }
      case 4: {
        return "All";
      }
      default: {
        return "No Tags";
      }
    }
  };

  const formatDate = (ele) => {
    const dateTime = new Date(ele);
    const dateOnly = dateTime.toISOString().split("T")[0];
    return dateOnly;
  };
  return (
    <div className="content-wrapper">
      <div className="container-fluid">
        {news && (
          <div className="row p-4 gap-4">
            <div className="col-12">
              <div className="fw-bold text-2xl">{news.title}</div>
            </div>
            <div className="col-12">
              <img
                src={`https://companion-7656.s3.ap-south-1.amazonaws.com/${news.imageUrl}`}
                alt={news.title}
                className="w-full h-[350px] object-cover"
              />
            </div>
            <div className="col-12">
              <ul className="">
                {Object.keys(news.content).map((list) =>
                  news.content[list].summary ? (
                    <li>
                      <h5>{news.content[list]?.summary}</h5>
                    </li>
                  ) : news.content[list].heading ? (
                    <li>
                      <h6>
                        {news.content[list]?.heading.replaceAll("\n", "")}
                      </h6>
                    </li>
                  ) : (
                    <li>
                      {news.content[list]?.subHeading.replaceAll("\n", "")}
                    </li>
                  )
                )}
              </ul>
            </div>
            <div className="col-12">
              <div className="flex gap-4">
                <div className="">
                  <span className="fw-bold">author :</span> {news.author.name}
                </div>
                <div className="">
                  <span className="fw-bold">published at :</span>
                  {formatDate(news.created_at)}
                </div>
                <div className="">
                  <span className="fw-bold">last modified at :</span>
                  {formatDate(news.updated_at)}
                </div>
                <div className="">
                  <span className="fw-bold">tags : </span>
                  {getCategory(news.category)}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewNews;
