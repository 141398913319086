import React, { useState } from "react";
import MenuBarMobile from "./MenuBarMobile";
import SideBar from "./SideBar";

export default function Layout({ pageTitle, children }) {
  // Concatenate page title (if exists) to site title
  let titleConcat = "Responsive Sidebar Example";
  if (pageTitle) titleConcat = pageTitle;

  document.title = titleConcat;

  // Mobile sidebar visibility state
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <>
      <div className="min-h-screen">
        <div className="flex">
          <SideBar show={showSidebar} setter={setShowSidebar} />
          <div className="flex flex-col flex-grow w-screen md:w-full min-h-screen">
            <MenuBarMobile setter={setShowSidebar} />
            {children}
          </div>
        </div>
      </div>
    </>
  );
}
