import { create } from 'zustand';

const useWebSocketStore = create((set, get) => ({
    socket: null,
    messages: [],
    connect: (url) => {
        const ws = new WebSocket(url);

        ws.onopen = () => {
            console.log('WebSocket connection opened');
            ws.send('Hello from Zustand WebSocket!');
        };

        ws.onmessage = (event) => {
            console.log('Message received:', event.data);
            set((state) => ({ messages: [...state.messages, event.data] }));
        };

        ws.onerror = (error) => {
            console.error('WebSocket error:', error.message);
        };

        ws.onclose = () => {
            console.log('WebSocket connection closed');
            set({ socket: null });
        };

        set({ socket: ws });
    },
    sendMessage: (message) => {
        const socket = get().socket;
        if (socket && socket.readyState === WebSocket.OPEN) {
            socket.send(message);
        } else {
            console.error('WebSocket is not open');
        }
    },
    disconnect: () => {
        const socket = get().socket;
        if (socket) {
            socket.close();
            set({ socket: null });
        }
    },
}));
export default useWebSocketStore;
