import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const COMMENT_API = {
    getAllComments: (token, newsId, page) => {
        return axios.request({
            method: 'get',
            url: `${BACKEND_URL}news-comments/${newsId}/${page}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },
    replyComment: (token, payload) => {
        return axios.request({
            method: 'post',
            url: `${BACKEND_URL}reply-comment`,
            body: payload,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }
}