// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, initializeFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: "AIzaSyD2CyuHUUwVLgG6m7rFRXVMPGpJCzvq8W8",
    authDomain: "companion-849a7.firebaseapp.com",
    projectId: "companion-849a7",
    storageBucket: "companion-849a7.appspot.com",
    messagingSenderId: "607516425473",
    appId: "1:607516425473:web:476ac15a7ada3c9929af00",
    measurementId: "G-50ZM6H5EH6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

