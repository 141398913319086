import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const ANALYTICS_API = {
    getAnalytics: (token, startDate, endDate) => {
        return axios.request({
            method: 'get',
            url: `${BACKEND_URL}get-analytics?startDate=${startDate}&endDate=${endDate}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },
}