import AWS from "aws-sdk";
import { v4 as uuidv4 } from 'uuid';

const s3Client = new AWS.S3({
    region: 'ap-south-1',
    credentials: {
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_S3_SECURITY_KEY
    }
})

export const postImage = async (data, key) => {


    const newUuid = uuidv4();
    const newKey = `news/${newUuid}.${data.name.split(".").reverse()[0]}`;
    const command = {
        Bucket: 'companion-7656',
        Key: newKey,
        ContentType: data.type,
        ACL: 'public-read' // Set ACL to 'public-read'
    };

    try {
        const uploadUrl = await s3Client.getSignedUrlPromise('putObject', command);
        await fetch(uploadUrl, {
            method: 'PUT',
            body: data,
            headers: {
                'Content-Type': data.type,
            },
        });

        return newKey
    } catch (error) {
        return false;
    }
}

export const deleteImage = async (key) => {
    const deleteParams = {
        Bucket: 'companion-7656',
        Key: key
    };

    try {
        const data = await s3Client.deleteObject(deleteParams).promise();
        return data
    } catch (error) {
        return false
    }
}
