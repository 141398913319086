import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAuth } from "../helper/AuthContext";
import { FaEdit, FaRegEye, FaTag } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import TooltipComponent from "../components/TooltioComponent";
import { BiLike } from "react-icons/bi";
import "../css/scrolbarWidth.css";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { TfiComments } from "react-icons/tfi";

const AllNews = () => {
  const [allNews, setAllNews] = useState([]);
  const backEndUrl = process.env.REACT_APP_BACKEND_URL;
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    getAllNews();
  }, []);

  const getAllNews = async () => {
    const response = await axios.get(`${backEndUrl}get-news`, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    if (response.data) {
      setAllNews(response.data);
    }
  };

  // function capitalizeFirstLetter(word) {
  //   if (!word) return ""; // handle empty input
  //   return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  // }

  const handleEdit = (id) => {
    navigate(`/edit-news/${id}`);
  };

  const handleView = (id) => {
    navigate(`/view-news/${id}`);
  };

  const handleDelete = async (id) => {
    const response = await axios.delete(`${backEndUrl}delete-news/${id}`, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    if (response.status === 200) {
      Swal.fire({
        title: "News Deleted!",
        text: "Successfully Deleted News",
        icon: "success",
      });
      setAllNews(response.data);
    } else {
      Swal.fire({
        title: "News Deleted!",
        text: "Not able to deleted news",
        icon: "error",
      });
    }
  };

  const handleComment = (id) => {
    try {
      navigate(`/news-comments/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="content-wrapper">
      <div className="container-fluid">
        <div className="row mt-2">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h1 className="fw-bold">All News</h1>
              </div>
              <div className="card-body">
                <div className="row">
                  {allNews.length > 0 ? (
                    allNews.map((ele) => (
                      <div className="col-md-4 mb-2" key={ele._id}>
                        <div className="card">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-12 border-b-2">
                                <p className="text-xl fw-bold ">{ele.title}</p>
                              </div>
                              <div className="col-12 py-2 border-b-2">
                                <img
                                  src={`https://companion-7656.s3.ap-south-1.amazonaws.com/${ele.imageUrl}`}
                                  className="h-[150px] w-100 object-fill"
                                  alt={ele.title}
                                />
                              </div>
                              <div className="col-12 line-clamp-3">
                                <ul>
                                  {Object.keys(ele.content).map((list) => (
                                    <li>
                                      {ele.content[list]?.heading ||
                                        ele.content[list]?.subHeading}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="card-footer">
                            <div className="row items-center">
                              <div className="col-10">
                                <div className="flex gap-2">
                                  <div className="">
                                    <TooltipComponent
                                      title={`Like : ${ele.likeCount ?? 0}`}
                                    >
                                      <BiLike className="text-blue-500 hover:cursor-pointer text-xl " />
                                    </TooltipComponent>
                                  </div>
                                  <TooltipComponent title={`Edit`}>
                                    <FaEdit
                                      className="text-[#00347a] hover:text-blue-500 hover:cursor-pointer text-xl "
                                      onClick={() => handleEdit(ele._id)}
                                    />
                                  </TooltipComponent>
                                  <TooltipComponent title={`View`}>
                                    <FaRegEye
                                      className="text-[#ffff00] hover:text-yellow-500 hover:cursor-pointer text-xl "
                                      onClick={() => handleView(ele._id)}
                                    />
                                  </TooltipComponent>
                                  <TooltipComponent
                                    title={`Comments: ${ele.comments.length}`}
                                  >
                                    <TfiComments
                                      className="text-[#008cff] hover:text-blue-700 hover:cursor-pointer text-xl "
                                      onClick={() => handleComment(ele._id)}
                                    />
                                  </TooltipComponent>
                                  <TooltipComponent title={`Delete`}>
                                    <MdDelete
                                      className="text-red-700 hover:text-red-500 hover:cursor-pointer text-xl "
                                      onClick={() => handleDelete(ele._id)}
                                    />
                                  </TooltipComponent>

                                  <TooltipComponent
                                    title={`tags : ${ele.tag?.map(
                                      (tag) => tag.label
                                    )}`}
                                  >
                                    <FaTag className="text-blue-500 hover:cursor-pointer text-xl" />
                                  </TooltipComponent>
                                </div>
                              </div>
                              <div className="col-2">
                                <TooltipComponent
                                  title={`author : ${ele.author.name}`}
                                >
                                  <img
                                    src={`${ele.author.profileImg}`}
                                    alt={ele.author.name}
                                    width={50}
                                    height={50}
                                    className=" rounded-full"
                                  />
                                </TooltipComponent>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No News Uploaded .....</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllNews;
