import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const NOTIFICATION_API = {
    sendCustomNotification: (token, payload) => {
        return axios.request({
            method: 'post',
            url: `${BACKEND_URL}send-custom-notification`,
            data: payload,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }
}