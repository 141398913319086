import React, { useEffect } from "react";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../helper/firebase_setup";
import axios from "axios";
import { useAuth } from "../helper/AuthContext";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const signIn = () => {
    // console.log(provider);
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        if (user) {
          axios
            .post(`${backendUrl}login`, {
              email: user.email,
              name: user.displayName,
              image: user.photoURL,
              token: user.accessToken,
            })
            .then((res) => {
              if (res.data.loggedIn === 1) {
                login();
                navigate("/");
                sessionStorage.setItem("userInfo", JSON.stringify(res.data));
              } else if (res.data.loggedIn === 2) {
                navigate("/verify");
              }
            })
            .catch((err) => {
              Swal.fire({
                title: "Error!",
                text: "Something went wrong in login",
                icon: "error",
              });
            });
        }
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log(error);
        // ...
      });
  };

  useEffect(() => {
    initializeApp(firebaseConfig);
  }, []);

  return (
    <div className="content-wrapper">
      <div className="container-fluid">
        <div className="row text-center py-4">
          <div className="col-12">
            <h1>Please Login First ...........</h1>
          </div>
          <div className="col-12 flex justify-center py-4">
            <div
              className="btn btn-outline-primary btn-sm"
              onClick={() => signIn("google")}
            >
              <div className="flex gap-2 items-center px-4 py-2">
                <div className="">
                  <img
                    src={require("../assets/google_logo.png")}
                    width={20}
                    height={20}
                    alt="login"
                  />
                </div>
                <div className="fw-semibold">Login</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
