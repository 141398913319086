import React from "react";
import ReactEcharts from "echarts-for-react";

const BarChart = ({ chartOption }) => {
  return (
    <div>
      <ReactEcharts
        option={chartOption}
        style={{ height: 450, width: "100%" }}
        opts={{ renderer: "svg" }} // Optionally use 'canvas' or 'svg'
      />
    </div>
  );
};

export default BarChart;
