import React, { useEffect, useRef } from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min'; // Ensure Bootstrap JS is included

const TooltipComponent = ({ title, children }) => {
  const tooltipRef = useRef(null);

  useEffect(() => {
    const tooltipTriggerEl = tooltipRef.current;
    if (tooltipTriggerEl) {
      const tooltip = new window.bootstrap.Tooltip(tooltipTriggerEl);
      // Clean up the tooltip instance when component unmounts
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  return (
    <div ref={tooltipRef} data-bs-toggle="tooltip" title={title}>
      {children}
    </div>
  );
};

export default TooltipComponent;
