import React from "react";

const VerifyInfo = () => {
  return (
    <div className="content-wrapper">
      <div className="container-fluid">
        <div className="row gap-8 text-center py-4">
          <div className="col-12">
            <div className="text-3xl fw-bolder">OOPs Sorry !</div>
            <div className="text-lg fw-semibold">
              It's seem you are not authorize
            </div>
          </div>
          <div className="col-12 flex justify-center">
            <img
              src={require("../assets/authentication.png")}
              alt="not authorized"
              width={450}
            />
          </div>
          <div className="col-12">
            <div className=" text-sm fw-light">
              Please Re Login Once authorized by host
            </div>
            <div className=" text-xs fw-lighter">
              Any issue please, contact to MR. MANEISH SIR
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyInfo;
