import React, { useEffect, useState } from "react";
import Select from "react-select";
import { TAG_API } from "../services/tagsService";
import { useAuth } from "../helper/AuthContext";
import { LOCATION_API } from "../services/locationService";
import { USER_API } from "../services/userService";
import Swal from "sweetalert2";
import { NOTIFICATION_API } from "../services/notificationService";

const CustomNotification = () => {
  const { user } = useAuth();
  const [tagOptions, setTagOptions] = useState([]);
  const [tag, setTag] = useState("");
  const [startAge, setStartAge] = useState("");
  const [endAge, setEndAge] = useState("");
  const [city, setCity] = useState("");
  const [classes, setClasses] = useState("");
  const [gender, setGender] = useState("");
  const [category, setCategory] = useState("");
  const [searchCity, setSearchCity] = useState("");
  const [allCity, setAllCity] = useState([]);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [response, setResponse] = useState("");
  const [allusers, setAllusers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(new Set());
  const [loginType, setLoginType] = useState("");

  const categoryOption = [
    { label: "SC/ST", value: "SC/ST" },
    { label: "OBC", value: "OBC" },
    { label: "GENERAL", value: "General" },
  ];

  const loginTypeOption = [
    { label: "Student", value: "Student" },
    { label: "Parent", value: "Parent" },
  ];

  const classOption = [
    { label: "IX", value: "IX" },
    { label: "X", value: "X" },
    { label: "XI", value: "XI" },
    { label: "XII", value: "XII" },
  ];

  const filteredOptions = allCity
    .filter((ele) =>
      ele.name.toLowerCase().startsWith(searchCity.toLowerCase())
    )
    .slice(0, 5);

  const genderOption = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];

  const handleCategory = (category) => {
    setCategory(category.value);
  };

  const fetAllCities = async () => {
    try {
      const res = await LOCATION_API.GetIndiaCities();
      if (res && res.data) {
        setAllCity(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getTags = async () => {
      try {
        const page = 1;
        const res = await TAG_API.getAllTags(page, user.token);
        if (res.status === 200) {
          console.log(res.data);

          setTagOptions(res.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getTags();
    fetAllCities();
  }, []);

  const handleSearch = async () => {
    try {
      let payload = {};
      setSearchLoading(true);
      if (
        category === "" &&
        tag === "" &&
        classes === "" &&
        city === "" &&
        gender === "" &&
        loginType === ""
      ) {
        Swal.fire({
          title: "Error!",
          text: "please select the filter first",
          icon: "error",
          timer: 1000,
        });
        setSearchLoading(false);
        return;
      } else {
        payload["category"] = category;
        payload["tag"] = tag;
        payload["studyingIn"] = classes;
        payload["city"] = city;
        payload["gender"] = gender;
        payload["loginType"] = loginType;
      }
      const res = await USER_API.filterUsers(user.token, payload);
      if (res.status === 200) {
        setAllusers(res.data.filter((item) => item.fcmToken));
        setSearchLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectAll = (event) => {
    // If checked, add all user fcmTokens to the set
    allusers.forEach((user) => {
      setSelectedUsers((prevState) => {
        const newSelectedUsers = new Set(prevState);
        if (newSelectedUsers.has(user.fcmToken)) {
          newSelectedUsers.delete(user.fcmToken); // Remove if already selected
        } else {
          newSelectedUsers.add(user.fcmToken); // Add if not selected
        }
        return newSelectedUsers;
      });
    });
  };

  const handleUserSelect = (event, fcmToken) => {
    setSelectedUsers((prevState) => {
      const newSelectedUsers = new Set(prevState);
      if (newSelectedUsers.has(fcmToken)) {
        newSelectedUsers.delete(fcmToken); // Remove if already selected
      } else {
        newSelectedUsers.add(fcmToken); // Add if not selected
      }
      return newSelectedUsers;
    });
  };

  const validateForm = async () => {
    let error = 0;
    if (title === "") {
      error += 1;
    }

    if (message === "") {
      error += 1;
    }

    if (error > 0) return false;
    return true;
  };

  const handleSubmit = async () => {
    try {
      let fcmTokens = [];
      for (const token of selectedUsers) {
        fcmTokens.push(token);
      }

      if (fcmTokens.length > 0 && validateForm()) {
        const payload = {
          fcmTokens: fcmTokens,
          title: title,
          message: message,
        };
        const res = await NOTIFICATION_API.sendCustomNotification(
          user.token,
          payload
        );
        if (res.status === 200) {
          Swal.fire({
            title: "Success",
            text: res.data.message,
            icon: "success",
            timer: 1500,
          });
        }
      }
    } catch (error) {}
  };

  return (
    <div className="container my-2">
      <div className="card">
        <div className="card-header">
          <h5>Add Filters</h5>
        </div>
        <div className="card-body">
          <div className="row">
            {/* select filters to get users accoring to the filters */}
            <div className="col-6 my-2">
              <h5 className="fw-semibold">
                Category <sup className="text-danger">*</sup>
              </h5>
              <div className="col-12">
                <Select
                  onChange={handleCategory}
                  options={categoryOption}
                  value={categoryOption.find((ele) => ele.value === category)}
                />
              </div>
            </div>
            <div className="col-6 my-2">
              <h5 className="fw-semibold">
                Tag <sup className="text-danger">*</sup>
              </h5>
              <div className="col-12">
                <Select
                  onChange={(tag) => setTag(tag.value)}
                  options={tagOptions.map((ele) => ({
                    label: ele.label,
                    value: ele._id,
                  }))}
                  value={tagOptions.find((ele) => ele._id === tag)}
                />
              </div>
            </div>
            <div className="col-6 my-2">
              <label htmlFor="" className=" font-semibold">
                Age Category
              </label>
              <div className="d-flex flex-wrap mt-2 items-center">
                <div className="col-4">
                  <input
                    type="number"
                    name="fromAge"
                    id=""
                    className="form-control"
                    placeholder="start age"
                    onChange={(e) => setStartAge(e.target.value)}
                    value={startAge}
                  />
                </div>
                <div className="col-2 text-center">to</div>
                <div className="col-4">
                  <input
                    type="number"
                    name="toAge"
                    id=""
                    className="form-control"
                    placeholder="End age"
                    onChange={(e) => setEndAge(e.target.value)}
                    value={endAge}
                  />
                </div>
              </div>
            </div>
            <div className="col-6 my-2">
              <label htmlFor="" className=" font-semibold">
                City
              </label>
              <div className="col-12 mt-2">
                <Select
                  onChange={(city) => setCity(city.value)}
                  options={filteredOptions.map((ele) => ({
                    label: ele.name,
                    value: ele.name,
                  }))}
                  value={allCity.find((ele) => ele.name === city)}
                  isSearchable={true}
                  onInputChange={(inputValue) => setSearchCity(inputValue)}
                />
              </div>
            </div>
            <div className="col-6 my-2">
              <label htmlFor="" className=" font-semibold">
                Studying In
              </label>
              <div className="col-12 mt-2">
                <Select
                  onChange={(classes) => setClasses(classes.value)}
                  options={classOption}
                  value={classOption.find((ele) => ele.value === classes)}
                />
              </div>
            </div>
            <div className="col-6 my-2">
              <label htmlFor="" className=" font-semibold">
                Gender
              </label>
              <div className="col-12 mt-2">
                <Select
                  onChange={(gender) => setGender(gender.value)}
                  options={genderOption}
                  value={genderOption.find((ele) => ele.value === gender)}
                />
              </div>
            </div>
            <div className="col-6 my-2">
              <label htmlFor="" className=" font-semibold">
                Select LoginType
              </label>
              <div className="col-12 mt-2">
                <Select
                  onChange={(login) => setLoginType(login.value)}
                  options={loginTypeOption}
                  value={loginTypeOption.find((ele) => ele.value === loginType)}
                />
              </div>
            </div>
            <button
              disabled={searchLoading}
              className="btn btn-primary"
              onClick={handleSearch}
            >
              {searchLoading ? "Searching..." : "Search Users"}
            </button>

            {/* select particular user to send notification */}
            <div className="col-12 mt-2">
              <div className="card">
                <div className="card-header">
                  <h5>Select Users</h5>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table
                      className="table caption-top table-bordered"
                      style={{ maxHeight: "300px", overflowY: "auto" }}
                    >
                      <caption>
                        <h5>List of users</h5>
                      </caption>
                      <thead>
                        <tr>
                          <th>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="selectAll"
                                onChange={handleSelectAll}
                                checked={selectedUsers.size === allusers.length}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="selectAll"
                              >
                                Select All
                              </label>
                            </div>
                          </th>
                          <th>Profile Image</th>
                          <th>Name</th>
                          <th>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allusers.map((ele, index) => (
                          <tr key={index + 1}>
                            <td>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="selectAll"
                                  value={ele.fcmToken}
                                  onChange={(event) =>
                                    handleUserSelect(event, ele.fcmToken)
                                  } // Capture individual checkbox change
                                  checked={selectedUsers.has(ele.fcmToken)} // Check if the user is selected
                                />
                              </div>
                            </td>
                            <td className="d-flex justify-center">
                              <img
                                src={ele.profileImg}
                                height={40}
                                width={40}
                                alt={ele.name}
                                className=" rounded-full object-cover"
                              />
                            </td>
                            <td>{ele.name}</td>
                            <td>{ele.email}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* Send notification to selected user */}
            <div className="col-12 mt-2">
              <div className="card">
                <div className="card-header">
                  <h5>Send Notification</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 my-2">
                      <label>Title</label>
                      <input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Enter Notification Title"
                        className="form-control"
                      />
                    </div>
                    <div className="col-12 my-2">
                      <label>Message</label>
                      <input
                        type="text"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Enter Notification Message"
                        className="form-control"
                      />
                    </div>
                    <button
                      disabled={loading}
                      className="btn btn-primary"
                      onClick={handleSubmit}
                    >
                      {loading ? "Sending..." : "Send Notification"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomNotification;
