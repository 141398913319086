import { create } from 'zustand';
import { LOCATION_API } from '../services/locationService';

const useStateStore = create((set) => ({
    states: [], // To store the list of states
    loading: false, // To track the loading state
    error: null, // To handle errors

    fetchStates: async () => {
        set({ loading: true, error: null }); // Set loading state to true
        try {
            const response = await LOCATION_API.GetIndianStates();
            set({ states: response.data, loading: false }); // Update states with response data
        } catch (err) {
            set({ error: err.message, loading: false }); // Handle errors
        }
    },
}));

export default useStateStore;
