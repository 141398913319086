import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useAuth } from "../helper/AuthContext";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import { TAG_API } from "../services/tagsService";
import { LOCATION_API } from "../services/locationService";
import { deleteImage, postImage } from "../helper/s3_bucket";
import useStateStore from "../Stores/stateStore";
import useUserStore from "../Stores/userStore";

const News = () => {
  const backEndUrl = process.env.REACT_APP_BACKEND_URL;
  const { states } = useStateStore();
  const { colleges } = useUserStore();
  const [news, setNews] = useState("");
  const [image, setImage] = useState(null);
  const { user } = useAuth();
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [buttonLink, setButtonLink] = useState("");
  const [buttonName, setButtonName] = useState("");
  const [redirectLink, setRedirectLink] = useState("");
  const [tagOptions, setTagOptions] = useState([]);
  const [tag, setTag] = useState([]);
  const [startAge, setStartAge] = useState("");
  const [endAge, setEndAge] = useState("");
  const [city, setCity] = useState("");
  const [classes, setClasses] = useState("");
  const [gender, setGender] = useState("");
  const { id } = useParams();
  const [state, setState] = useState("");
  const [college, setCollege] = useState("");
  const [filters, setFilters] = useState({
    reservationSpecific: false,
    carrierSpecific: false,
    ageCategory: false,
    schoolSpecific: false,
    citySpecific: false,
    classSpecific: false,
    genderSpecific: false,
    stateSpecific: false,
    collegeSpecific: false,
  });
  const [searchCity, setSearchCity] = useState("");
  const [allCity, setAllCity] = useState([]);
  const [error, setError] = useState({
    reservationSpecific: false,
    carrierSpecific: false,
    ageCategory: false,
    schoolSpecific: false,
    citySpecific: false,
    classSpecific: false,
    genderSpecific: false,
    stateSpecific: false,
    collegeSpecific: false,
  });
  const [uploadedImg, setUploadedImg] = useState(null);
  const [expireAt, setExpireAt] = useState("");
  const inputRef = useRef(null);
  const [hashtagInput, setHashtagInput] = useState("");
  const [hashtagError, setHashtagError] = useState("");

  const categoryOption = [
    { label: "SC/ST", value: "SC/ST" },
    { label: "OBC", value: "OBC" },
    { label: "GENERAL", value: "General" },
    { label: "ALL", value: "All" },
  ];

  const classOption = [
    { label: "IX", value: "IX" },
    { label: "X", value: "X" },
    { label: "XI", value: "XI" },
    { label: "XII", value: "XII" },
  ];

  const navigate = useNavigate();

  const validateFilters = () => {
    let errorCount = 0;
    if (filters.ageCategory) {
      if (startAge === "" || endAge === "") {
        setError((prev) => ({ ...prev, ageCategory: true }));
        errorCount += 1;
      } else {
        setError((prev) => ({ ...prev, ageCategory: false }));
      }
    }
    if (filters.carrierSpecific) {
      if (tag === "") {
        setError((prev) => ({ ...prev, carrierSpecific: true }));
        errorCount += 1;
      } else {
        setError((prev) => ({ ...prev, carrierSpecific: false }));
      }
    } else {
      setTag("");
    }
    if (filters.citySpecific) {
      if (city === "") {
        setError((prev) => ({ ...prev, citySpecific: true }));
        errorCount += 1;
      } else {
        setError((prev) => ({ ...prev, citySpecific: false }));
      }
    } else {
      setCity("");
    }
    if (filters.genderSpecific) {
      if (gender === "") {
        setError((prev) => ({ ...prev, genderSpecific: true }));
        errorCount += 1;
      } else {
        setError((prev) => ({ ...prev, genderSpecific: false }));
      }
    } else {
      setGender("");
    }
    if (filters.classSpecific) {
      if (classes === "") {
        setError((prev) => ({ ...prev, classSpecific: true }));
        errorCount += 1;
      } else {
        setError((prev) => ({ ...prev, classSpecific: false }));
      }
    } else {
      setClasses("");
    }
    if (filters.reservationSpecific) {
      if (category === "") {
        setError((prev) => ({ ...prev, reservationSpecific: true }));
        errorCount += 1;
      } else {
        setError((prev) => ({ ...prev, reservationSpecific: false }));
      }
    } else {
      setCategory("");
    }

    if (errorCount > 0) {
      return false;
    } else {
      return true;
    }
  };

  // const handleSaveNews = async () => {
  //   try {
  //     if (validateFilters() && news !== "" && image != null && title !== "") {
  //       // console.log(image);
  //       const uploadedImage = await postImage(image);

  //       if (uploadedImage) {
  //         setUploadedImg(uploadedImage);
  //         const response = await axios.post(
  //           `${backEndUrl}add-news`,
  //           {
  //             news: createHashMapFromContent(news),
  //             image: uploadedImage,
  //             type: image.type,
  //             category: category,
  //             title: title,
  //             button_text: buttonName,
  //             button_link: buttonLink,
  //             redirect: redirectLink,
  //             tag: tag && tag.length > 0 ? tag?.map((item) => item.value) : [],
  //             startAge: startAge,
  //             endAge: endAge,
  //             city: city,
  //             classes: classes,
  //             gender: gender,
  //             reservationSpecific: filters.reservationSpecific,
  //             ageCategory: filters.ageCategory,
  //             classSpecific: filters.classSpecific,
  //             carrierSpecific: filters.carrierSpecific,
  //             citySpecific: filters.citySpecific,
  //             genderSpecific: filters.genderSpecific,
  //             schoolSpecific: filters.schoolSpecific,
  //             expiresAt: expireAt,
  //           },
  //           {
  //             headers: {
  //               Authorization: `Bearer ${user.token}`,
  //             },
  //           }
  //         );
  //         if (response.status === 200) {
  //           Swal.fire({
  //             title: "News Added!",
  //             text: "Successfully Added News",
  //             icon: "success",
  //           });
  //           setNews("");
  //           setButtonLink("");
  //           setButtonName("");
  //           setCategory("");
  //           setImage(null);
  //           setRedirectLink("");
  //           setTitle("");
  //           setTag("");
  //           setUploadedImg(null);
  //           setStartAge("");
  //           setEndAge("");
  //           setCity("");
  //           setClasses("");
  //           setGender("");
  //           setFilters({
  //             reservationSpecific: false,
  //             carrierSpecific: false,
  //             ageCategory: false,
  //             schoolSpecific: false,
  //             citySpecific: false,
  //             classSpecific: false,
  //             genderSpecific: false,
  //           });
  //         } else {
  //           await deleteImage(uploadedImage);
  //           setUploadedImg(null);
  //         }
  //       } else {
  //         Swal.fire({
  //           title: "Error!",
  //           text: "Error in Uploading Image",
  //           icon: "error",
  //           confirmButtonText: "OK",
  //         });
  //       }
  //     } else {
  //       Swal.fire({
  //         title: "Error!",
  //         text: "Please Add News And Upload Image",
  //         icon: "error",
  //         confirmButtonText: "OK",
  //       });
  //     }
  //   } catch (err) {
  //     Swal.fire({
  //       title: "Error!",
  //       text: err,
  //       icon: "error",
  //       confirmButtonText: "OK",
  //     });
  //     if (uploadedImg) {
  //       await deleteImage(uploadedImg);
  //       setUploadedImg(null);
  //     }
  //   }
  // };

  const handleSaveNews = async () => {
    try {
      if (validateFilters() && news !== "" && image != null && title !== "") {
        const uploadedImage = await postImage(image);

        if (uploadedImage) {
          setUploadedImg(uploadedImage);
          const response = await axios.post(
            `${backEndUrl}add-news`,
            {
              news: createHashMapFromContent(news),
              image: uploadedImage,
              type: image.type,
              category: category,
              title: title,
              button_text: buttonName,
              button_link: buttonLink,
              redirect: redirectLink,
              tag: tag && tag.length > 0 ? tag.map((item) => item.value) : [],
              startAge: startAge,
              endAge: endAge,
              city: city,
              classes: classes,
              gender: gender,
              reservationSpecific: filters.reservationSpecific,
              ageCategory: filters.ageCategory,
              classSpecific: filters.classSpecific,
              carrierSpecific: filters.carrierSpecific,
              citySpecific: filters.citySpecific,
              genderSpecific: filters.genderSpecific,
              schoolSpecific: filters.schoolSpecific,
              expiresAt: expireAt || "",
              hashTag: hashtagInput,
            },
            {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            }
          );

          if (response.status === 200) {
            Swal.fire({
              title: "News Added!",
              text: "Successfully Added News",
              icon: "success",
            });
            resetForm();
          } else {
            await deleteImage(uploadedImage);
            setUploadedImg(null);
          }
        } else {
          showError("Error in Uploading Image");
        }
      } else {
        showError("Please Add News And Upload Image");
      }
    } catch (err) {
      showError(err.message || "An unexpected error occurred.");
      if (uploadedImg) {
        await deleteImage(uploadedImg);
        setUploadedImg(null);
      }
    }
  };

  // Helper functions
  const showError = (message) => {
    Swal.fire({
      title: "Error!",
      text: message,
      icon: "error",
      confirmButtonText: "OK",
    });
  };

  const resetForm = () => {
    setNews("");
    setButtonLink("");
    setButtonName("");
    setCategory("");
    setImage(null);
    setRedirectLink("");
    setTitle("");
    setTag([]);
    setUploadedImg(null);
    setStartAge("");
    setEndAge("");
    setCity("");
    setClasses("");
    setGender("");
    setHashtagInput("");
    // Reset filters to default values
    setFilters({
      reservationSpecific: false,
      carrierSpecific: false,
      ageCategory: false,
      schoolSpecific: false,
      citySpecific: false,
      classSpecific: false,
      genderSpecific: false,
    });
  };

  const handleRemoveHashtag = (tagToRemove) => {
    const updatedTags = hashtagInput
      .split(/\s+/)
      .filter((tag) => tag !== tagToRemove)
      .join(" ");
    setHashtagInput(updatedTags);
  };

  const handleSaveEditNews = async () => {
    try {
      // createHashMapFromContent(news);
      // return;
      const uploadedImage = image ? await postImage(image) : null;

      if (validateFilters() && news !== "" && title !== "") {
        // console.log(tag);

        const response = await axios.put(
          `${backEndUrl}edit-news/${id}`,
          {
            news: createHashMapFromContent(news),
            image: uploadedImage,
            type: image?.type,
            category: category,
            title: title,
            button_text: buttonName,
            button_link: buttonLink,
            redirect: redirectLink,
            tag: tag && tag.length > 0 ? tag?.map((item) => item.value) : [],
            startAge: startAge,
            endAge: endAge,
            city: city,
            classes: classes,
            gender: gender,
            reservationSpecific: filters.reservationSpecific,
            ageCategory: filters.ageCategory,
            classSpecific: filters.classSpecific,
            carrierSpecific: filters.carrierSpecific,
            citySpecific: filters.citySpecific,
            genderSpecific: filters.genderSpecific,
            schoolSpecific: filters.schoolSpecific,
            expiresAt: expireAt,
            hashTag: hashtagInput,
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );

        if (response.status === 200) {
          Swal.fire({
            title: "News Updated!",
            text: "Successfully Updated News",
            icon: "success",
          });
          navigate("/news");
        } else {
          Swal.fire({
            title: "Error!",
            text: "Please Add News And Upload Image",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "Error!",
        text: error,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const handleUploadImage = (e) => {
    setImage(e.target.files[0]);
  };

  const handleTitle = (e) => {
    setTitle(e.target.value);
  };

  const handleButtonLink = (e) => {
    setButtonLink(e.target.value);
  };

  const handleButtonName = (e) => {
    setButtonName(e.target.value);
  };

  const HandleRedirectLink = (e) => {
    setRedirectLink(e.target.value);
  };

  const handleCategory = (category) => {
    setCategory(category.value);
  };

  useEffect(() => {
    if (id) {
      getSingleNews(id);
    }
  }, [id]);

  useLayoutEffect(() => {
    const getTags = async () => {
      try {
        let hasMoreTags = true;
        let page = 1;
        while (hasMoreTags) {
          const res = await TAG_API.getAllTags(page, user.token);
          if (res.status === 200) {
            if (res.data.length < 10) hasMoreTags = false;
            setTagOptions((prev) => [...prev, ...res.data]);
            page += 1;
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    getTags();
  }, []);

  const getSingleNews = async (id) => {
    const response = await axios.get(`${backEndUrl}get-particular-news/${id}`, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    const data = response.data;
    if (data) {
      if (data.content && typeof data.content === "object") {
        setNews(convertHashMapToContent(data.content));
      } else {
        setNews(data.content);
      }
      setButtonLink(data.buttonLink);
      setButtonName(data.buttonText);
      setCategory(data.category);
      setImage(null);
      setRedirectLink(data.redirect);
      setTitle(data.title);
      if (data.tag && data.tag.length > 0) {
        const tags = data.tag.map((ele) => ({
          label: ele.label,
          value: ele._id,
        }));
        setTag(tags);
      }
      setClasses(data.classes ?? "");
      setStartAge(data.startAge ?? "");
      setEndAge(data.endAge ?? "");
      setCity(data.city ?? "");
      setFilters({
        reservationSpecific: data.reservationSpecific ?? false,
        carrierSpecific: data.carrierSpecific ?? false,
        ageCategory: data.ageCategory ?? false,
        schoolSpecific: data.schoolSpecific ?? false,
        citySpecific: data.citySpecific ?? false,
        classSpecific: data.classSpecific ?? false,
        genderSpecific: data.genderSpecific ?? false,
      });
      setGender(data.gender ?? "");
      setExpireAt(data.expiresAt ?? "");
      setHashtagInput(data.hashTag ?? "");
    }
  };

  useEffect(() => {
    if (tag && tag.length > 0) {
      tag
        ?.map((label) => {
          const match = tagOptions.find((item) => item._id === label);
          return match ? { label: match.label, value: match._id } : null; // Return `null` if no match
        })
        .filter((item) => item !== null);
    }
  }, [tagOptions, tag]);

  useEffect(() => {
    const fetAllCities = async () => {
      try {
        const res = await LOCATION_API.GetIndiaCities();
        if (res && res.data) {
          setAllCity(res.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetAllCities();
  }, []);

  const filteredOptions = allCity
    .filter((ele) =>
      ele.name.toLowerCase().startsWith(searchCity.toLowerCase())
    )
    .slice(0, 5);

  const genderOption = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];

  const insertTagAtCursor = (tag) => {
    const tagString = `<${tag}/>`;
    const textarea = inputRef.current;

    // Get the current cursor position
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    // Insert the tag at the cursor position
    const newText = news.substring(0, start) + tagString + news.substring(end);

    // Update the state with the new text
    setNews(newText);

    // Move the cursor to right after the inserted tag
    setTimeout(() => {
      textarea.selectionStart = textarea.selectionEnd =
        start + tagString.length;
      textarea.focus();
    }, 0);
  };

  function addSummary() {
    news.length > 0 && insertTagAtCursor("summary");
    inputRef.current.focus();
  }

  function addHeading() {
    news.length > 0 && insertTagAtCursor("heading");
    inputRef.current.focus();
  }

  function addSubHeading() {
    news.length > 0 && insertTagAtCursor("subHeading");
    inputRef.current.focus();
  }

  function createHashMapFromContent(content) {
    // Regular expression to match the different sections
    const regex = /(.*?)<(summary|heading|subHeading)\/>/g;
    let match;
    let index = 1; // To assign keys like 1, 2, 3...
    const hashMap = {};

    // Split the content into lines
    const lines = content.split("\n");

    // Loop over each line to extract matches
    for (const line of lines) {
      // Check if the line contains any tags
      if (
        line.includes("<summary/>") ||
        line.includes("<heading/>") ||
        line.includes("<subHeading/>")
      ) {
        // Use regex to find matches in the line
        while ((match = regex.exec(line)) !== null) {
          const text = match[1].trim(); // The text before the tag
          const tag = match[2]; // The tag (summary, heading, subHeading)

          if (text) {
            // Add the extracted text to the hashmap with the corresponding tag
            hashMap[index] = { [tag]: text };
            index++;
          }
        }
      } else {
        // If the line does not contain any tags, treat it as a summary
        const trimmedLine = line.trim();
        if (trimmedLine) {
          hashMap[index] = { summary: trimmedLine };
          index++;
        }
      }
    }
    return hashMap;
  }

  function convertHashMapToContent(hashMap) {
    let content = "";

    // Loop over the hashmap keys in numerical order
    Object.keys(hashMap).forEach((key) => {
      const entry = hashMap[key];

      // Check the type of entry and append the corresponding tag to the content
      if (entry.summary) {
        content += `${entry.summary}<summary/>\n`;
      } else if (entry.heading) {
        content += `${entry.heading}<heading/>\n`;
      } else if (entry.subHeading) {
        content += `${entry.subHeading}<subHeading/>\n`;
      }
    });

    return content;
  }

  return (
    <div className="content-wrapper">
      <div className="container-fluid">
        <div className="row mt-2">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h3 className="fw-bold">Add News</h3>
              </div>
              <div className="card-body">
                <div className="row">
                  {/* add title */}
                  <div className="col-12 my-2">
                    <h5 className="fw-semibold">
                      Add Title <sup className="text-danger">*</sup>
                    </h5>
                  </div>
                  <div className="col-12">
                    <textarea
                      className="form-control"
                      onChange={handleTitle}
                      placeholder="Type Something....."
                      value={title}
                    />
                  </div>
                  {/* add content */}
                  <div className="col-12 my-2">
                    <h5 className="fw-semibold">
                      Add Content <sup className="text-danger">*</sup>
                    </h5>
                  </div>
                  <div className="col-12">
                    {/* <div className="form-group"> */}
                    <textarea
                      onChange={(e) => setNews(e.target.value)}
                      value={news}
                      placeholder="Enter news in bullets..."
                      rows={10}
                      className="form-control"
                      ref={inputRef}
                    />
                    <div className="d-flex gap-x-2">
                      <div
                        className="btn btn-sm btn-primary my-2"
                        onClick={addSummary}
                      >
                        Add Summary
                      </div>
                      <div
                        className="btn btn-sm btn-primary my-2"
                        onClick={addHeading}
                      >
                        Add headings
                      </div>
                      <div
                        className="btn btn-sm btn-primary my-2"
                        onClick={addSubHeading}
                      >
                        Add Subheadings
                      </div>
                    </div>

                    {/* </div> */}
                  </div>
                  {/* upload image */}
                  <div className="col-12 my-2 fw-semibold">
                    <h5>
                      Upload Image <sup className="text-danger">*</sup>
                    </h5>
                  </div>
                  <div className="col-12">
                    <input
                      type="file"
                      accept="image/*,.gif"
                      className="form-control w-50"
                      onChange={handleUploadImage}
                      file={image}
                    />
                  </div>
                  {/* button link */}
                  <div className="col-12 my-2 fw-semibold">
                    <h5>Button Link</h5>
                  </div>
                  <div className="col-12">
                    <input
                      type="text"
                      className="form-control w-50"
                      onChange={handleButtonLink}
                      placeholder="Please Paste the button link"
                      value={buttonLink}
                    />
                  </div>
                  {/* button name */}
                  <div className="col-12 my-2 fw-semibold">
                    <h5>Button Name</h5>
                  </div>
                  <div className="col-12">
                    <input
                      type="text"
                      className="form-control w-50"
                      onChange={handleButtonName}
                      placeholder="Please Enter Button Text"
                      value={buttonName}
                    />
                  </div>
                  {/* source link */}
                  <div className="col-12 my-2 fw-semibold">
                    <h5>Source Link</h5>
                  </div>
                  <div className="col-12">
                    <input
                      type="text"
                      onChange={HandleRedirectLink}
                      className="form-control w-50"
                      placeholder="Please paste redirect link"
                      value={redirectLink}
                    />
                  </div>
                  {/* news expiry */}
                  <div className="col-12 my-2">
                    <h5>News Expiry</h5>
                    <div className="w-50">
                      <input
                        type="date"
                        name="expireAt"
                        id="expiry"
                        onChange={(e) => setExpireAt(e.target.value)}
                        className="form-control"
                        value={expireAt}
                      />
                    </div>
                  </div>
                  {/* filter card */}
                  <div className="col-12 mt-2">
                    <div className="card">
                      <div className="card-header">Add Filters</div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12 my-2">
                            <div className="form-group d-flex flex-wrap gap-x-2">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="category1"
                                  onChange={(e) => {
                                    setFilters((prev) => ({
                                      ...prev,
                                      reservationSpecific: e.target.checked,
                                    }));
                                    if (!e.target.checked) {
                                      setCategory("");
                                    }
                                  }}
                                  checked={filters.reservationSpecific}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="category1"
                                >
                                  Reservation Specific
                                </label>
                              </div>

                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="category2"
                                  onChange={(e) => {
                                    setFilters((prev) => ({
                                      ...prev,
                                      carrierSpecific: e.target.checked,
                                    }));
                                    if (!e.target.checked) {
                                      setTag([]);
                                    }
                                  }}
                                  checked={filters.carrierSpecific}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="category2"
                                >
                                  Career Specific
                                </label>
                              </div>

                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="category3"
                                  onChange={(e) => {
                                    setFilters((prev) => ({
                                      ...prev,
                                      ageCategory: e.target.checked,
                                    }));
                                    if (!e.target.checked) {
                                      setStartAge("");
                                      setEndAge("");
                                    }
                                  }}
                                  checked={filters.ageCategory}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="category3"
                                >
                                  Age Category
                                </label>
                              </div>

                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="category4"
                                  onChange={(e) => {
                                    setFilters((prev) => ({
                                      ...prev,
                                      schoolSpecific: e.target.checked,
                                    }));
                                  }}
                                  checked={filters.schoolSpecific}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="category4"
                                >
                                  School Specific
                                </label>
                              </div>

                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="category5"
                                  onChange={(e) => {
                                    setFilters((prev) => ({
                                      ...prev,
                                      citySpecific: e.target.checked,
                                    }));
                                    if (!e.target.checked) {
                                      setCity("");
                                    }
                                  }}
                                  checked={filters.citySpecific}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="category5"
                                >
                                  City Specific
                                </label>
                              </div>

                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="category6"
                                  onChange={(e) =>
                                    setFilters((prev) => ({
                                      ...prev,
                                      classSpecific: e.target.checked,
                                    }))
                                  }
                                  checked={filters.classSpecific}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="category6"
                                >
                                  Class Specific
                                </label>
                              </div>

                              {/* Add College Specific */}
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="category8"
                                  onChange={(e) => {
                                    setFilters((prev) => ({
                                      ...prev,
                                      collegeSpecific: e.target.checked,
                                    }));
                                    if (!e.target.checked) {
                                      setCollege("");
                                    }
                                  }}
                                  checked={filters.collegeSpecific}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="category8"
                                >
                                  College Specific
                                </label>
                              </div>
                              {/* Add State Specific */}
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="category9"
                                  onChange={(e) =>
                                    setFilters((prev) => ({
                                      ...prev,
                                      stateSpecific: e.target.checked,
                                    }))
                                  }
                                  checked={filters.stateSpecific}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="category9"
                                >
                                  State Specific
                                </label>
                              </div>

                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="category7"
                                  onChange={(e) =>
                                    setFilters((prev) => ({
                                      ...prev,
                                      genderSpecific: e.target.checked,
                                    }))
                                  }
                                  checked={filters.genderSpecific}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="category7"
                                >
                                  Gender Specific
                                </label>
                              </div>
                            </div>
                          </div>
                          {filters.reservationSpecific && (
                            <div className="col-12 my-2">
                              <h5 className="fw-semibold">
                                Category <sup className="text-danger">*</sup>
                              </h5>
                              <div className="col-6">
                                <Select
                                  onChange={handleCategory}
                                  options={categoryOption}
                                  value={categoryOption.find(
                                    (ele) => ele.value === category
                                  )}
                                />
                              </div>
                            </div>
                          )}

                          {filters.reservationSpecific &&
                            error.reservationSpecific && (
                              <div className="text-danger">
                                Please Select category
                              </div>
                            )}

                          {filters.carrierSpecific && (
                            <div className="col-12 my-2">
                              <h5 className="fw-semibold">
                                Tag <sup className="text-danger">*</sup>
                              </h5>
                              <div className="col-6">
                                <Select
                                  onChange={(item, action) => {
                                    setTag(item);
                                  }}
                                  options={tagOptions.map((ele) => ({
                                    label: ele.label,
                                    value: ele._id,
                                  }))}
                                  value={tag}
                                  isMulti={true}
                                />
                              </div>
                            </div>
                          )}

                          {filters.carrierSpecific && error.carrierSpecific && (
                            <div className="text-danger">Please Select tag</div>
                          )}

                          {filters.ageCategory && (
                            <div className="col-12 my-2">
                              <label htmlFor="" className=" font-semibold">
                                Age Category
                              </label>
                              <div className="d-flex flex-wrap mt-2 items-center">
                                <div className="col-3">
                                  <input
                                    type="number"
                                    name="fromAge"
                                    id=""
                                    className="form-control"
                                    placeholder="start age"
                                    onChange={(e) =>
                                      setStartAge(e.target.value)
                                    }
                                    value={startAge}
                                  />
                                </div>
                                <div className="col-1 text-center">to</div>
                                <div className="col-3">
                                  <input
                                    type="number"
                                    name="toAge"
                                    id=""
                                    className="form-control"
                                    placeholder="End age"
                                    onChange={(e) => setEndAge(e.target.value)}
                                    value={endAge}
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                          {filters.ageCategory && error.ageCategory && (
                            <div className="text-danger">
                              Please don't forget to add start and end age
                            </div>
                          )}

                          {filters.citySpecific && (
                            <div className="col-12 my-2">
                              <label htmlFor="" className=" font-semibold">
                                City
                              </label>
                              <div className="col-6 mt-2">
                                <Select
                                  onChange={(city) => setCity(city.value)}
                                  options={filteredOptions.map((ele) => ({
                                    label: ele.name,
                                    value: ele.name,
                                  }))}
                                  value={allCity
                                    .filter((ele) => ele.name === city)
                                    .map((ele) => ({
                                      label: ele.name,
                                      value: ele.name,
                                    }))}
                                  isSearchable={true}
                                  onInputChange={(inputValue) =>
                                    setSearchCity(inputValue)
                                  }
                                />
                              </div>
                            </div>
                          )}

                          {filters.citySpecific && error.citySpecific && (
                            <div className="text-danger">
                              Please Select city
                            </div>
                          )}

                          {filters.classSpecific && (
                            <div className="col-12 my-2">
                              <label htmlFor="" className=" font-semibold">
                                Studying In
                              </label>
                              <div className="col-6 mt-2">
                                <Select
                                  onChange={(classes) =>
                                    setClasses(classes.value)
                                  }
                                  options={classOption}
                                  value={classOption.find(
                                    (ele) => ele.value === classes
                                  )}
                                />
                              </div>
                            </div>
                          )}

                          {filters.classSpecific && error.classSpecific && (
                            <div className="text-danger">
                              Please Select class
                            </div>
                          )}

                          {/* College Specific */}
                          {filters.collegeSpecific && (
                            <div className="col-12 my-2">
                              <label htmlFor="" className="font-semibold">
                                Select College
                              </label>
                              <div className="col-6 mt-2">
                                <Select
                                  onChange={(college) =>
                                    setCollege(college.value)
                                  }
                                  options={colleges.map((ele) => ({
                                    label: ele.name,
                                    value: ele.name,
                                  }))}
                                  value={colleges
                                    .filter((ele) => ele.name === college)
                                    .map((ele) => ({
                                      label: ele.name,
                                      value: ele.name,
                                    }))}
                                />
                              </div>
                            </div>
                          )}
                          {filters.collegeSpecific && error.collegeSpecific && (
                            <div className="text-danger">
                              Please select a college
                            </div>
                          )}
                          {/* State Specific */}
                          {filters.stateSpecific && (
                            <div className="col-12 my-2">
                              <label htmlFor="" className="font-semibold">
                                Select State
                              </label>
                              <div className="col-6 mt-2">
                                <Select
                                  onChange={(state) => {
                                    setState(state.value);
                                  }}
                                  options={states.map((ele) => ({
                                    label: ele.name,
                                    value: ele.name,
                                  }))}
                                  value={states
                                    .filter((ele) => ele.name === state)
                                    .map((ele) => ({
                                      label: ele.name,
                                      value: ele.name,
                                    }))}
                                />
                              </div>
                            </div>
                          )}
                          {filters.stateSpecific && error.stateSpecific && (
                            <div className="text-danger">
                              Please select a state
                            </div>
                          )}

                          {filters.genderSpecific && (
                            <div className="col-12 my-2">
                              <label htmlFor="" className=" font-semibold">
                                Gender
                              </label>
                              <div className="col-6 mt-2">
                                <Select
                                  onChange={(gender) => setGender(gender.value)}
                                  options={genderOption}
                                  value={genderOption.find(
                                    (ele) => ele.value === gender
                                  )}
                                />
                              </div>
                            </div>
                          )}

                          {filters.genderSpecific && error.genderSpecific && (
                            <div className="text-danger">
                              Please Select gender
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Hashtag Section */}
                  <div className="col-12 my-2">
                    <h5 className="fw-semibold">
                      Add Hashtags <sup className="text-danger">*</sup>
                    </h5>
                  </div>
                  <div className="col-12">
                    <textarea
                      className="form-control"
                      placeholder="Enter hashtags separated by spaces (e.g., #news #update)"
                      value={hashtagInput}
                      onChange={(e) => setHashtagInput(e.target.value)}
                    />
                    {hashtagError && (
                      <div className="text-danger mt-2">{hashtagError}</div>
                    )}
                  </div>
                  <div className="col-12 my-2">
                    <h6 className="fw-semibold">Hashtags Preview:</h6>
                    <div className="d-flex flex-wrap gap-2">
                      {hashtagInput
                        .split(/\s+/)
                        .filter((tag) => tag.length > 1)
                        .map((tag, index) => (
                          <span
                            key={index}
                            className="badge bg-primary d-flex align-items-center"
                          >
                            {tag}
                            <button
                              className="btn-close btn-close-white ms-2"
                              onClick={() => handleRemoveHashtag(tag)}
                              aria-label="Remove"
                            />
                          </span>
                        ))}
                    </div>
                  </div>

                  {/* save button */}
                  <div className="col-12 my-2 fw-semibold flex justify-end">
                    <button
                      className="btn btn-primary"
                      onClick={id ? handleSaveEditNews : handleSaveNews}
                    >
                      Save News
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
