// AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const location = useLocation()

    const login = () => {
        setIsAuthenticated(true);
    };

    const logout = () => {
        setIsAuthenticated(false);
        sessionStorage.removeItem('userInfo')
    };

    useEffect(() => {
        const userData = JSON.parse(sessionStorage.getItem('userInfo'))
        if (userData) {
            setUser(userData)
            setIsAuthenticated(true)
            navigate(location.pathname)
        }

    }, [isAuthenticated, location.pathname])

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout, user, setUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
