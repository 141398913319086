import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import News from "./pages/News";
import AllNews from "./pages/AllNews";
import { AuthProvider } from "./helper/AuthContext";
import PrivateRoute from "./components/PrivateRoute";
import Login from "./pages/Login";
import VerifyInfo from "./pages/VerifyInfo";
import ViewNews from "./pages/ViewNews";
import Tags from "./pages/Tags";
import CustomNotification from "./pages/CustomNotification";
import CommentsPage from "./pages/CommentPage";
import QuizForm from "./pages/QuizForm";
import useStateStore from "./Stores/stateStore";
import useWebSocketStore from "./Stores/webSocketStore";
const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

const App = () => {
  const { fetchStates } = useStateStore();
  const { messages, connect, sendMessage, disconnect } = useWebSocketStore();

  useEffect(() => {
    fetchStates();
  }, []);

  useEffect(() => {
    const url = `ws://${SOCKET_URL}`;
    connect(url);

    // Disconnect on component unmount
    return () => {
      disconnect();
    };
  }, [connect, disconnect]);

  return (
    <AuthProvider>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Layout pageTitle={"Home"} children={<Home />} />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-news"
          element={
            <PrivateRoute>
              <Layout pageTitle={"News"} children={<News />} />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-news/:id"
          element={
            <PrivateRoute>
              <Layout pageTitle={"News"} children={<News />} />
            </PrivateRoute>
          }
        />
        <Route
          path="/view-news/:id"
          element={
            <PrivateRoute>
              <Layout pageTitle={"News"} children={<ViewNews />} />
            </PrivateRoute>
          }
        />
        <Route
          path="/news-comments/:id"
          element={
            <PrivateRoute>
              <Layout pageTitle={"news-comments"} children={<CommentsPage />} />
            </PrivateRoute>
          }
        />
        <Route
          path="/news"
          element={
            <PrivateRoute>
              <Layout pageTitle={"News"} children={<AllNews />} />
            </PrivateRoute>
          }
        />
        <Route
          path="/tags"
          element={
            <PrivateRoute>
              <Layout pageTitle={"Tags"} children={<Tags />} />
            </PrivateRoute>
          }
        />
        <Route
          path="/notification"
          element={
            <PrivateRoute>
              <Layout
                pageTitle={"Send Notification"}
                children={<CustomNotification />}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-quiz"
          element={
            <PrivateRoute>
              <Layout pageTitle={"add-quiz"} children={<QuizForm />} />
            </PrivateRoute>
          }
        />
        <Route
          path="/login"
          element={<Layout pageTitle={"Login"} children={<Login />} />}
        />
        <Route
          path="/verify"
          element={
            <Layout pageTitle={"verify Info"} children={<VerifyInfo />} />
          }
        />
      </Routes>
    </AuthProvider>
  );
};

export default App;
