// userStore.js

import { create } from "zustand";

const useUserStore = create(set => ({
  user: null,
  colleges: [],
  login: async (email, password) => {
    // Login logic
    set({ user: { email, password } });
  },
  logout: () => {
    set({ user: null });
  },
  fetchColleges: async () => {

  }
}));

export default useUserStore;