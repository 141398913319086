import React, { useEffect, useState } from "react";
import BarChart from "../components/BarChart";
import { useAuth } from "../helper/AuthContext";
import { ANALYTICS_API } from "../services/AnalyticService";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const date = new Date();
  const options = {
    timeZone: "Asia/Kolkata", // Set timezone to India
    hour12: false,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  const localString = date.toLocaleString("en-US", options);
  const { user } = useAuth();
  const [startDate, setStartDate] = useState(localString);
  const [endDate, setEndDate] = useState(localString);
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  const fetchAnalytics = async () => {
    try {
      const res = await ANALYTICS_API.getAnalytics(
        user.token,
        startDate,
        endDate
      );
      setData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   if (startDate && endDate) {
  //     fetchAnalytics();
  //   }
  // }, [startDate, endDate]);

  const handleApplyFilter = () => {
    if (startDate && endDate) {
      fetchAnalytics();
    }
  };

  const titles = data?.mostVisited?.map((item) => item.newsDetails.title);
  const viewCounts = data?.mostVisited?.map((item) => item.viewCount);

  // Update the Option object
  const Option = {
    title: {
      text: "Most Visited News",
      left: "center",
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    xAxis: {
      type: "category",
      data: titles,
      axisLabel: {
        interval: 0,
        rotate: 30, // Adjust for longer titles
        margin: 8,
      },
    },
    yAxis: {
      type: "value",
      name: "Views",
    },
    series: [
      {
        name: "Views",
        type: "bar",
        data: viewCounts,
        barWidth: "60%",
        itemStyle: {
          color: "#73c0de",
          barBorderRadius: [4, 4, 0, 0],
        },
      },
    ],
  };

  const handleView = (ele) => {
    console.log(ele);
    navigate(`/view-news/${ele}`);
  };

  const TilesCard = (props) => {
    return (
      <div className="col-sm-4">
        <div className="card">
          <div className="card-body">
            <div className="flex justify-between">
              <div className="">
                <span className=" font-bold">{props.title}:</span>
                {props.data || 0}
              </div>
              {props.redirectId && (
                <FaExternalLinkAlt
                  className="hover:cursor-pointer"
                  onClick={() => handleView(props.redirectId)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="content-wrapper">
      <div className="container-fluid">
        <div className="row mt-2">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h1 className="fw-bold">Dashboard</h1>
              </div>
              <div className="card-body">
                {/* Date Filter */}

                <div className="row mb-3">
                  <div className="col-sm-4">
                    <label htmlFor="startDate" className="form-label">
                      Start Date:
                    </label>
                    <input
                      type="date"
                      id="startDate"
                      className="form-control"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="endDate" className="form-label">
                      End Date:
                    </label>
                    <input
                      type="date"
                      id="endDate"
                      className="form-control"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-4 d-flex align-items-end">
                    <button
                      className="btn btn-primary"
                      onClick={handleApplyFilter}
                    >
                      Apply
                    </button>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4">
                    <div className="card">
                      <div className="card-body">
                        <span className=" font-bold">Total Users:</span>
                        {data?.userCount || 0}
                      </div>
                    </div>
                  </div>
                  <TilesCard
                    title={"Most Visited News"}
                    data={data?.mostVisited[0]?.viewCount}
                    redirectId={data?.mostVisited[0]?.newsDetails?._id}
                  />
                  {data?.loginCounts?.map(
                    (ele, idx) =>
                      ele._id && (
                        <TilesCard
                          key={idx + 1}
                          title={`Total ${ele._id}`}
                          data={ele.count}
                          // redirectId={ele._id}
                        />
                      )
                  )}
                  {/* Chart */}
                  {data && (
                    <div className="col-12 mt-2">
                      <div className="card">
                        <div className="card-header">
                          <h1 className="fw-bold">Most Viewed News/Title</h1>
                        </div>
                        <div className="card-body">
                          <BarChart chartOption={Option} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
