import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";
import { ImNewspaper } from "react-icons/im";
import { FaTags } from "react-icons/fa";
import { AiFillMessage } from "react-icons/ai";

const SideBar = ({ show, setter }) => {
  const router = useLocation();
  const [isSubmenuOpen, setSubmenuOpen] = useState(false);

  const toggleSubmenu = () => {
    setSubmenuOpen(!isSubmenuOpen);
  };

  // Define our base class
  const className =
    "bg-[#00347a] w-[250px] transition-[margin-left] ease-in-out duration-500 fixed md:static top-0 bottom-0 left-0 z-40";
  // Append class based on state of sidebar visiblity
  const appendClass = show ? " ml-0" : " ml-[-250px] md:ml-0";

  // Clickable menu items
  const MenuItem = ({ icon, name, route }) => {
    // Highlight menu item based on currently displayed route
    const colorClass =
      router.pathname === route
        ? "text-white"
        : "text-white/50 hover:text-white";

    return (
      <Link
        to={route}
        onClick={() => {
          setter((oldVal) => !oldVal);
        }}
        className={`flex gap-1 [&>*]:my-auto text-md py-3 border-b-[1px] border-b-white/10 ${colorClass} no-underline`}
      >
        <div className="flex gap-1 justify-center items-center w-full">
          <div className="text-xl flex [&>*]:mx-auto w-[30px]">{icon}</div>
          <div>{name}</div>
        </div>
      </Link>
    );
  };

  // Overlay to prevent clicks in background, also serves as our close button
  const ModalOverlay = () => (
    <div
      className={`flex md:hidden fixed top-0 right-0 bottom-0 left-0 bg-black/20 z-30 text-white`}
      onClick={() => {
        setter((oldVal) => !oldVal);
      }}
    />
  );
  return (
    <>
      <div className={`${className}${appendClass}`}>
        <div className="p-2 flex justify-center">
          <Link href="/">
            {/*eslint-disable-next-line*/}
            <img
              src={require("../assets/logo-final@3x.png")}
              alt="Company Logo"
              width={64}
              height={64}
            />
          </Link>
        </div>
        <div className="flex flex-col">
          <MenuItem name="Home" route="/" icon={<AiFillHome />} />
          <MenuItem name="Add News" route="/add-news" icon={<ImNewspaper />} />
          <MenuItem name="News" route="/news" icon={<ImNewspaper />} />
          <MenuItem name="Tags" route="/tags" icon={<FaTags />} />
          <MenuItem
            name="Notification"
            route="/notification"
            icon={<AiFillMessage />}
          />
          <div>
            <div
              onClick={toggleSubmenu}
              className="flex items-center justify-center p-2 cursor-pointer"
            >
              <span
                className={`${isSubmenuOpen ? "text-white" : "text-gray-500"}`}
              >
                Quiz
              </span>
            </div>
            {isSubmenuOpen && (
              <div className="ml-4">
                <MenuItem
                  name="Create Quiz"
                  route="/add-quiz"
                  icon={<ImNewspaper />}
                />
                <MenuItem name="Quiz" route="/quizzes" icon={<ImNewspaper />} />
              </div>
            )}
          </div>
        </div>
      </div>
      {show ? <ModalOverlay /> : <></>}
    </>
  );
};

export default SideBar;
